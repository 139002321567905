<template>
    <base-form
        ref="form"
        v-model="form"
        :api="api"
        :mapper="mapper"
        :entity-id="entityId"
        :title="title"
        :disabled="disabled"
        :disable-save-button="form.isFinished"
        disable-delete-button
        @created="created"
        @updated="updated"
        @cancelled="cancelled"
        @deleted="deleted"
    >
        <form-panel>
            <form-one-col-row>
                <textarea-input
                    v-model="form.description"
                    v-validate="'required|space'"
                    label="labels.description"
                    name="description"
                    :error-messages="errors.collect('description')"
                    :data-vv-as="$t('labels.description')"
                ></textarea-input>
            </form-one-col-row>
            <form-two-col-row>
                <template v-slot:col1>
                    <date-picker-input
                        v-model="form.dueDate"
                        v-validate="'required'"
                        label="labels.due_date"
                        name="dueDate"
                        :error-messages="errors.collect('dueDate')"
                        :data-vv-as="$t('labels.due_date')"
                    ></date-picker-input>
                </template>
            </form-two-col-row>
            <form-two-col-row>
                <template v-slot:col1>
                    <entity-select-input
                        v-model="form.user"
                        label="labels.user"
                        name="user"
                        :entity="SelectApiType.USER"
                        autocomplete
                    ></entity-select-input>
                    <entity-select-input
                        v-model="form.ordering"
                        label="labels.order"
                        name="ordering"
                        :entity="SelectApiType.ORDER"
                        autocomplete
                    ></entity-select-input>
                </template>
                <template v-slot:col2>
                    <entity-select-input
                        v-model="form.company"
                        label="labels.company"
                        name="company"
                        :entity="SelectApiType.COMPANY"
                        autocomplete
                    ></entity-select-input>
                    <entity-select-input
                        v-model="form.restaurant"
                        label="labels.restaurant"
                        name="restaurant"
                        :entity="SelectApiType.RESTAURANT"
                        autocomplete
                    ></entity-select-input>
                </template>
            </form-two-col-row>
        </form-panel>
        <portal to="footer" order="1">
            <v-btn
                v-if="form.id"
                :text="form.isFinished"
                :disabled="form.isFinished"
                class="ml-4"
                color="success"
                @click="finishConfirm = true"
            >
                <v-icon left>check_box</v-icon>
                {{
                    form.isFinished
                        ? $t('labels.finished')
                        : $t('actions.finish')
                }}
            </v-btn>
        </portal>
        <confirm-modal
            v-model="finishConfirm"
            :text="$t('texts.confirm_todo_finish', { todo: form.id })"
            :processing="finishProgress"
            @onApprove="doFinish"
        ></confirm-modal>
    </base-form>
</template>

<script>
import routeType from '@/router/routeType'
import RestapiType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'
import SelectApiType from '@/api/SelectApiClientType'
import FormBase from '@/components/mixins/FormBase'
import FormPanel from '@/components/form/FormPanel'
import FormOneColRow from '@/components/form/FormOneColRow'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import TextareaInput from '@/components/form/Inputs/TextareaInput'
import DatePickerInput from '@/components/form/Inputs/DatePickerInput'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import ConfirmModal from '@/components/confirm/ConfirmModal'
import TodoApiClient from '@/api/RestApi/TodoApiClient'

export default {
    components: {
        FormPanel,
        FormOneColRow,
        FormTwoColRow,
        TextareaInput,
        DatePickerInput,
        EntitySelectInput,
        ConfirmModal,
    },
    mixins: [FormBase],
    data() {
        return {
            api: RestapiType.TODOS,
            mapper: MapperType.TODO_FORM,
            form: {
                description: null,
                dueDate: null,
                user: null,
                ordering: null,
                company: null,
                restaurant: null,
                isFinished: false,
            },
            SelectApiType,
            editRoute: routeType.TODO_EDIT,
            listRoute: routeType.TODO_LIST,
            finishConfirm: false,
            finishProgress: false,
        }
    },
    computed: {
        title: function () {
            return (this.form.description || '-').slice(0, 40)
        },
    },
    methods: {
        doFinish() {
            this.finishProgress = true
            TodoApiClient.finish(this.form.id).then(() => {
                this.finishProgress = false
                this.$refs.form.load()
            })
        },
    },
}
</script>
