<template>
    <div>
        <todo-form></todo-form>
    </div>
</template>

<script>
import todoForm from '@/components/pages/todo/Form'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: {
        todoForm,
    },

    mixins: [dirtyForm],
}
</script>
